.panel {
  &>:last-child {
    &>:first-child {
      padding: 0;
    }
  }
}

.title {
  margin: 0 0 20px 0;
}