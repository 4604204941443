.btn_whatsapp {
  position: fixed;
  z-index: 3;
  bottom: 20px;
  right: 20px;
  height: 56px !important;
  width: 56px !important;
  justify-content: center;
  align-items: center;
  display: flex;
}