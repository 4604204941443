.layout {
  min-height: 100vh;
  flex-direction: row;
}

.sider__header {
  height: 32px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(5, 5, 5, 0.06)
}

.sider__logo {
  height: 30px;
}

@media screen and (max-width: 992px) {
  .sider__header {
    height: 32px;
  }
}

.main_content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 0;
  margin-bottom: 40px;
}

.main_content_admin {
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 0;
  margin-bottom: 40px;
}

.header__logo {
  height: 30px;
  cursor: pointer;
}