.login {
  background: linear-gradient(60deg, rgba(205, 210, 217, 1) 0%, rgba(244, 245, 247, 1) 100%)
}

.login__title {
  color: grey !important;
}

.login__text {
  color: grey !important;
}

.login__container {
  flex-basis: 100% !important;
  flex-grow: 0 !important;
  max-width: 600px;
  margin: 0 auto;
}

.login__card {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100vh;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
}

@media screen and (max-width: 480px) {
  .login__card {
    padding-left: 16px;
    padding-right: 16px;
  }

  .ant-layout-content {
    padding: 20px 0 0 0;
  }
}

.login__card_header {
  padding: 24px;
}

.login__card_text {
  text-align: center;
}

.login__card_socials {
  padding: 0 24px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.login__card_socials_facebook {
  padding: 12px 30px;
  border: 1px solid #1677ff;
  border-radius: 8px;
  cursor: pointer;
}

.login__card_socials_microsoft {
  padding: 12px 30px;
  border: 1px solid #1677ff;
  border-radius: 8px;
  cursor: pointer;
}

.login__card_socials_google {
  padding: 12px 30px;
  border: 1px solid #1677ff;
  border-radius: 8px;
  cursor: pointer;
}

.login__card_socials_divider {}

.divider {
  text-align: center;
}

.divider::after {
  left: 0.5em;
  margin-right: -50%;
  background: linear-gradient(90deg, hsla(0, 0%, 46%, .4), hsla(0, 0%, 46%, .4), transparent);
  content: "";
  display: inline-block;
  width: 30%;
  height: 1px;
  position: relative;
  vertical-align: middle;
}

.divider::before {
  content: "";
  display: inline-block;
  width: 30%;
  height: 1px;
  position: relative;
  vertical-align: middle;
  background: linear-gradient(90deg, transparent, hsla(0, 0%, 46%, .4), hsla(0, 0%, 46%, .4));
  right: 0.5em;
  margin-left: -50%;
}

.login__footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.login__error {
  text-align: center;
  color: red;
}

.login__right {
  border: none;
  box-shadow: rgb(106 101 255 / 20%) 5px 7px 15px 0px inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2rem);
  height: calc(100vh - 2rem);
  position: relative;
  text-align: center;
  margin: 16px;
  padding-left: 80px;
  padding-right: 80px;
  opacity: 1;
  background: linear-gradient(60deg, rgba(205, 210, 217, 1) 0%, rgba(244, 245, 247, 1) 100%);
  color: rgb(52, 71, 103);
  border-radius: 0.75rem;
  overflow: hidden;
}

.login__img {
  width: 100%;
  max-width: 250px;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
}

.login__logo_container {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.login__logo_mobile {
  height: 60px;
}