@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sider {
  display: block;
}

@media screen and (max-width: 991px) {
  .sider {
    display: none;
  }

  .content {
    padding-top: 55px;
  }
}

.loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ant-pagination {
  margin: 16px !important;
}

.table-row {
  cursor: pointer;
}